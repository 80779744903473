.regimen-submit-container {
    display: flex;
    justify-content: center;
    align-items: center; 
}

.make-a-regiment {
    position: relative;
    left: -80px;
}

.exercises-on-regimen-form {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    position: relative;
    top: 50px;
}

.regimen-form {
    position: relative;
    top: 50px;
    left: 50px;
    width: 300px;
}

.exercise-item-container {
    min-width: 220px;
    min-height: 220px;
    border: 12px;
}

.spacer {
    width: 400px;
}

.exercise-text {
    max-width: 100px;
    max-height: 100px;
}

.description-field {

    padding-top: 1rem;
    /* width: 25rem; */
}

#regimen-submit-master-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0f3460;
    padding-bottom: 60px;
    height: 100%;
    width: 100%;
    padding-right: 50px;
}

.regimen-form {
    top: -600px;
    left: 100px;
    z-index: 0;
    width: 30%;
    height: 23px;
}

#submit-footer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
        left: 80px;
}

#regimen-submit-text-container {
    display: flex;
    justify-content: center;
    align-items: center; 
    flex-direction: column;
}

.submit-regimen-button {
    max-width: 20px;
}

#footer-sub-container {
    max-width: 300px;
}

.regimen-form-interior {
    display: block;
    background-color: #3166ec;
    border-radius: 10%;
    max-width: 300px;
    position: relative;
    left: 90px;
    min-height: 430px;
    box-shadow: 10px 10px;
    padding-bottom: 50px;
}

#regimen-text-boxes {
    position: relative;
    top: 40px;
}

.make-a-regiment {
    position: relative;
    top: 15px;
    left: 80px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

#footer-sub-container {
    position: relative;
    top: 40px;
}

.selected-exercises {
    position: relative;
    left: -60px;
    width: 260px;
    min-height: 150px;
    background-color: rgb(104, 104, 109);
    z-index: 40;
    border-radius: 10%;
        display: grid;
            grid-template-columns: 100px 100px;
            grid-row: auto auto;
            grid-column-gap: 20px;
            grid-row-gap: 20px;
}

.button {
    color: black;
    border: none;
    padding: 5px;
    font-size: 15px;
    height: 30px;
    width: 100px;
    box-shadow: 0 2px 4px darkslategray;
    cursor: pointer;
    transition: all 0.2s ease;
    border-radius: 10%;
}

#submit-button {
    background-color: #abc9ff;
    width: 150px;
    position: relative;
    left: -10px;
}

#submit-button:active {
    background-color: rgb(99, 6, 6);
    box-shadow: 0 0 2px rgb(67, 4, 4);
    transform: translateY(2px);
}

#description-field {
    position: relative;
    left: 65px;
}

.selected-exercises-individuals {
    display: block;
    background-color: #e94560;
    width: 80px;
    border-radius: 10%;
    height: 80px;
    padding: 10px;
    position: relative;
    left: 15px;
}

/* .selected-exercises {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
} */

#remove-from-list {
    background-color: #abc9ff;
    width: 80px;
    height: 40px;
    position: relative;
    top: 10px;
    right: 2px;
}

.selected-spacer {
    height: 20000px;
}

.exercise-type-submit-list {
    color: white;
}

.almost-done-2 {
    display:inline;
    float:left;
}

.almost-done {
    display: inline;
    float: left;
}

