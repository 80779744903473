.regimen-index-item-container {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  padding: 5px;
  justify-content: center;
  align-items: center;
  background-color: #E94560;
  border-radius: 10px;
  width: 300px;
  min-height: 30px;
  
}

.regimen-index-item {
  display: flex;
  align-items:baseline;
  justify-content: space-between;
  padding-top: 20px;
}

.reg-shw-exrc-list {
  min-width: 120px;
  /* padding-right: 80px; */
  /* position: relative; */
  /* left: 30px; */
  background-color: #0f3460;
  padding: 10px;
  border-radius: 10px;
  border: 2px solid rgb(255, 255, 255)
}

.reg-shw-musc-list {
  max-width: 100px;
  background-color: #0f3460;
  padding: 10px;
  border-radius: 10px;
  border: 2px solid rgb(255, 255, 255)
}

.regimen-index-item-container {
  padding: 20px;
}

.regi-auth {
  align-self: center;
}

.regimen-index-item-title {
  align-self: center;
}

.regi-idx-title-auth {
  display: flex;
  flex-direction: column;
  background-color: rgb(145, 132, 219);
  border-radius: 10%;
  min-height: 30px;
  min-width: 20px;
  padding: 5px;
  border: 2px solid rgb(84, 38, 144);
  
}

.regi-idx-exrc-title {
  align-self: center;
  position: relative;
  left: 30px;
  width: 70px;
}


.regi-idx-exrc-cont {
  min-width: 100px;
}

.regi-idx-musc-cont {
  min-width: 150px;
}

.regi-idx-musc-title {
  align-self: center;
  max-width: 110px;
  position: relative;
}

.regi-idx-footer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-top: 5px;
  width: 300px;
}

#regi-idx-delete {
  height: 30px;
  background-color: #70b8e0;
  color: rgb(5, 6, 55);
  width: 50px;
}

#regi-idx-delete:active {
  background-color: rgb(10, 64, 83);
  box-shadow: 0 0 2px black;
  transform: translateY(2px);
}

#regi-idx-edit {
  height: 30px;
  width: 50px;
  background-color: #0f3460;
  color: #E94560;
}

#regi-idx-edit:active {
  background-color: rgb(10, 64, 83);
  box-shadow: 0 0 2px black;
  transform: translateY(2px);
}

.regi-idx-list-exrc{
  max-width: 120px;
}

.reg-idx-avg-diff {
  background-color: aliceblue;
  padding: 5px;
  color: black;
  border-radius: 10%;
  border: 2px solid #0f3460;
}

.regi-idx-likes {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 300px;
  position: relative;
  left: -100px;
}

.likes-spacer {
  width: 10px;
}
