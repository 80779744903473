.index-exercise-content {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 250px;
    min-height: 200px;
    background-color: #E94560;
    border-radius: 10%;
    color: white;
    z-index: -10;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.index-exercise-container {
    padding: 30px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.exercise-index-link-name-form {
    color: white;
    font-size: 20px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.index-container-text {
    position: relative;
    top: -10px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.spacer-index {
    max-width: none;
    min-height: 10px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.exercise-index-difficulty-form:hover {
    border-bottom: 2px solid white;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.exercise-type-index:hover {
    border-bottom: 2px solid white;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.assisted-index {
    position: relative;
    top: 10px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.assisted-index:hover {
border-bottom: 2px solid white;
}