

.exercise-show-container{
  position: relative;
  display: flex;
  flex-direction: column;
  background-color:#0F3460 ;
  padding-bottom: 100px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  padding-left: 25px;
  padding-right: 25px;
}

.exercise-show-top{
  /* position: absolute; */
  display: flex;
  flex-direction: column;
  width: 100%;
  /* background-color:#16213E; */
  padding-top: 20px;
  border-bottom: black;
  
  /* align-items: center; */
  /* justify-content: center; */
}

.top-info{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  height: 100px;
}

.exercise-show-bottom{
  /* position:absolute; */
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  color: white;
  justify-content: center;
  /* width: 45%; */
}

.exercise-show-description{
  display: flex;
  flex-direction: column;
  padding-right: 20px;
  
}

.difficulty-label, .muscle-label, .type-label, .equipment-label,
 .description-label, .description-diff, .description-asst {
  color:goldenrod ;
  font-size: 35px;
  padding-bottom: 5px;
}
.difficulty-content,
.muscle-content,
.type-content,
.equipment-content{
  color: white;
  font-size: 25px;
}

.description-content{
  font-size: 25px;
}

.exercise-show-title{
  font-size: xx-large;
  font-weight: bold;
  align-self: center;
  color:#E94560 ;
}

.footer-container-show {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  top: 80px;
}

