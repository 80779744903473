.exercise-index-index {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding-left: 30px;
    background-color: #0F3460;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

#instructions-content {
    position: relative;
    top: 15px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

#instructions {
    background-color: #0F3460;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

#index-key-blob {
    display: block;
    background-color: wheat;
    width: 300px;
    height: 150px;
    position: relative;
    padding-left: 40px;
    border-radius: 10%;
    padding-top: 20px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

#instructions-content {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

#key-key {
    border-bottom: 10px, solid, black;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.spacer-index {
    min-height: 20px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

#instructions-words {
        display: block;
            background-color: wheat;
            width: 300px;
            height: 150px;
            position: relative;
            padding-left: 40px;
            padding-right: 30px;
            border-radius: 10%;
            padding-top: 20px;
            font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.spacer-index-width {
    width: 40px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}