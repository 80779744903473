/* .exercise-regimen-modal {
    background-color: rgb(252, 255, 59);
    color: rgb(111, 102, 102);
    border: none;
    padding: 5px;
    font-size: 15px;
    height: 30px;
    width: 80px;
    box-shadow: 0 2px 4px black;
    cursor: pointer;
    transition: all 0.2s ease;
    border-radius: 10%;
}

.exercise-regimen-modal:active {
    background-color: rgb(107, 119, 32);
    box-shadow: 0 0 2px black;
    transform: translateY(2px);
}

.exercise-regimen-submit {
    background-color: rgb(66, 67, 50);
    color: rgb(255, 255, 255);
    border: none;
    padding: 5px;
    font-size: 15px;
    height: 30px;
    width: 80px;
    box-shadow: 0 2px 4px black;
    cursor: pointer;
    transition: all 0.2s ease;
    border-radius: 10%;
}

.exercise-regimen-submit:active {
    background-color: grey;
    box-shadow: 0 0 2px black;
    transform: translateY(2px);
}

.button-spacer {
    min-height: 20px;
    min-width: 20px;
}

.exercise-text {
    border-radius: 10%;
    min-width: 200px;
    min-height: 200px;
    background-color: rgb(234, 54, 54);
    z-index: -10;
}

.exercise-text-container {
    display: flex;
    justify-content: center;
    align-items: center; 
}

.regimen-buttons-container {
    padding-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 10px;
    border-color: black;
}

.exercise-name-form {
    display: flex;
    justify-content: center;
    align-items: center;
}

.exercise-difficulty-form {
    padding-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
} */

.exercise-text {
    background-color: #e94560;
    padding: 30px;
    border-radius: 20px;
    min-height: 100px;
    width: 200px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.regimen-buttons-container{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.button-spacer {
    min-width: 20px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.exercise-text {
    position: relative;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.exercise-content {
    min-height: 90px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.difficulty-label-submit-page:hover {
    border-bottom: 1px solid white;
    max-width: 60px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}


.exercise-name-form {
    border-bottom: 2px solid white;
    min-width: 70px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.button-submit {
    background-color: rgb(229, 220, 93);
    color: black;
    border: none;
    padding: 5px;
    font-size: 15px;
    height: 30px;
    width: 60px;
    box-shadow: 0 2px 4px darkslategray;
    cursor: pointer;
    transition: all 0.2s ease;
    border-radius: 10%;
}

#add-exrc-button {
    background-color: #2c59ca;
        color: white;
}

#add-exrc-button:active {
    background-color: rgb(99, 6, 6);
    box-shadow: 0 0 2px rgb(67, 4, 4);
    transform: translateY(2px);
}

#add-exrc-info-button {
    background-color: #abc9ff;
}

#add-exrc-info-button:active {
    background-color: rgb(99, 6, 6);
    box-shadow: 0 0 2px rgb(67, 4, 4);
    transform: translateY(2px);
}