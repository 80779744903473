.modal-background {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 10;
}

.modal-child {
    background-color: rgb(59, 51, 51);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-color: rgb(162, 152, 152);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -10;
}

.errors {
    color: white;
}

.modal-footer-login {
    display: flex;
    justify-content: center;
    align-items: center;    
}

.modal-footer-signup {
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-form-container {
    background-color: rgb(209, 48, 48);
    padding: 20px;
    border-radius: 10px;
    /* width: 500px; */
    align-items: center;
    justify-content: center;
    width: 330px;
    position: relative;

}

.signup-form-container {
    padding: 20px;
    border-radius: 10px;
    background-color: rgb(216, 50, 50);
    width: 340px;
}

.submit-button {
    display: flex;
    justify-content: center;
    align-items: center;
}

.spacer {
    min-height: 20px;
}


.login-signup-submit {
    background-color: white;
    color: black;
    border: none;
    padding: 5px;
    font-size: 15px;
    height: 30px;
    width: 80px;
    box-shadow: 0 2px 4px darkslategray;
    cursor: pointer;
    transition: all 0.2s ease;
    border-radius: 10%;
}

.login-signup-submit:active {
    background-color: rgb(77, 74, 74);
    box-shadow: 0 0 2px black;
    transform: translateY(2px);
}

.close-modal-button {
    background-color: rgba(42, 47, 42, 0.392);
    color: rgb(3, 3, 3);
    border: none;
    padding: 5px;
    font-size: 15px;
    height: 30px;
    width: 80px;
    box-shadow: 0 2px 4px black;
    cursor: pointer;
    transition: all 0.2s ease;
    border-radius: 10%;
}

.close-modal-button:active {
    background-color: grey;
    box-shadow: 0 0 2px black;
    transform: translateY(2px);
}


.submission-field {
    border-radius: 16%;
}

.signup-form {
    display: flex;
    justify-content: center;
    align-items: center; 
    position: relative;
    left: 90px;
}

.login-form {
    display: flex;
    justify-content: center;
    align-items: center; 
    position: relative;
    left: 90px;
}

.greeting-login {
    display: flex;
    justify-content: center;
    align-items: center; 
}

.demo-login {
    background-color: rgb(229, 220, 93);
    color: black;
    border: none;
    padding: 5px;
    font-size: 15px;
    height: 30px;
    width: 80px;
    box-shadow: 0 2px 4px darkslategray;
    cursor: pointer;
    transition: all 0.2s ease;
    border-radius: 10%;
}

.demo-login:active {
    background-color: rgb(64, 64, 3);
    box-shadow: 0 0 2px black;
    transform: translateY(2px);
}

#close-modal-button-login-form {
    height: 30px;
    width: 80px;
    background-color: rgb(26, 26, 173);
    color:rgb(255, 255, 255);
    position: relative;
    left: 45px;
}

#close-modal-button-login-form:active {
    background-color: rgb(5, 8, 78);
    box-shadow: 0 0 2px black;
    transform: translateY(2px);
}

.submit-button {
    position: relative;
    right: 165px;
    top: 30px;
}

#submit-button-close {
    height: 30px;
    width: 80px;
    position: relative;
    left: 40px;
    background-color: rgb(26, 26, 173);
    color:rgb(255, 255, 255);
}

#submit-button-close:active {
    background-color: rgb(5, 8, 78);
    box-shadow: 0 0 2px black;
    transform: translateY(2px);
}