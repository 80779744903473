.regi-shw-pg {
    background-color: #0f3460;
    width: 96%;
    height: 100%;
    padding-bottom: 400px;
    border-left: 30px solid #e94560;
    border-right: 30px solid #e94560;
}


.regi-shw-pg-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 40px;
}

.reg-shw-title {
    color: #daa520;
    font-size: xx-large;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}

.reg-shw-title-underline {
    border-top: 1px solid gray;
    color: rgba(167, 175, 182, 0.211);
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: italic;
}

.reg-shw-creator {
    color: #e94560;
    font-size: xx-large;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}

.reg-shw-creator-underline {
    border-top: 1px solid gray;
    color: rgba(167, 175, 182, 0.211);
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: italic;
}

.reg-shw-title-auth {
    position: relative;
    top: 40px;
}

.reg-shw-title-auth {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 400px;
}

.regi-exercises-desc-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 120px;
}

.regi-desc {
    color: #abc9ff;
    font-size: large;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid white;
    width: 250px;
}

.reg-shw-desc-body {
    width: 250px;
    overflow-wrap: break-word;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: rgb(12, 131, 235);
    font-size: large;
}

.regi-shw-exrc {
    position: relative;
    top: 80px;
    align-items: center;
    justify-content: center;
}

.regi-shw-exrc-grid-5 {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 120px;
    row-gap: 30px;
    position: relative;
    top: 30px;
    padding: 40px;
}

.regi-shw-exrc-grid-4 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 120px;
    row-gap: 30px;
    position: relative;
    top: 30px;
}

.regi-shw-exrc-grid-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 120px;
    row-gap: 30px;
    position: relative;
    top: 30px;
}

.regi-shw-exrc-grid-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 120px;
    row-gap: 30px;
    position: relative;
    top: 30px;
}

.regi-shw-exrc-grid-1 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 120px;
    row-gap: 30px;
    position: relative;
    top: 30px;
}

.regi-shw-exrc-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid beige;
    color: #daa520;
    font-weight: bold;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.regi-show {
    display: flex;
    align-items: center;
    justify-content: center;
}

.regi-shw-exrc-hard {
    display: flex;
    align-items: center;
    justify-content: center; 
    color:rgba(235, 21, 103, 0.807);
    font-weight: bold;
    font-size: x-large;
    position: relative;
    border-left: 2px solid rgb(206, 194, 194);
    border-right: 2px solid rgb(206, 194, 194);
}

.regi-shw-exrc-desc {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: #abc9ff;
    width: 150px;
}

.exercise-index-link-name-form {
    position: relative;
    bottom: -1px;
}

.regi-shw-footer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    top: -140px;
    position: absolute;
    top: 120px;
    left: 40%;
    border: 2px solid rgb(255, 255, 255);
    padding: 20px;
    background-color: #c75fde;
    border-radius: 10%;
}

#regi-show-delete {
    height: 40px;
    background-color: #70b8e0;
    color:rgb(5, 6, 55);
}
#regi-show-delete:active {
    background-color: rgb(10, 64, 83);
    box-shadow: 0 0 2px black;
    transform: translateY(2px);
}
 
#regi-show-edit {
    height: 40px;
    background-color: #e94560;
}

#regi-show-edit:active {
    background-color: rgb(80, 11, 11);
    box-shadow: 0 0 2px black;
    transform: translateY(2px);
}

.newCommentForm {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 300px;
}

.commentsDiv {
    color:rgb(206, 194, 194);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.reg-idx-avg-diff-not-creator {
    width: 10%;
    background-color: aliceblue;
    border-radius: 10%;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    left: 45%;
    top: -600px;
}

.regi-shw-exrc-diff {
    color: aqua;
}

.assisted-index {
    color: rgb(189, 61, 189);
}

.not-creator-diff-regi-shw {
    position: absolute;
    top: 120px;
    left: 45%;
    border: 2px solid blanchedalmond;
    padding: 20px;
    background-color: #e94560;
    border-radius: 10%;
}

.comment-box {
    width: 206px;
    height: 80px;
    padding: 20px;
    background-color:rgb(206, 194, 194);
    padding-bottom: 10px;
}

.comment-banner {
    color: #abc9ff;
    font-size: large;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid white;
    width: 250px;
}

.comment-submission {
    background-color: #abc9ff;
    width: 150px;
    position: relative;
    left: 50px;
    justify-content: center;
    display: flex;
    border-radius: 30%;
    padding: 10px;
}

.posters-name {
    color: #e94560;
    font-weight: bold;
    display: flex;
    padding: 5px;
}

.comment-body {
    color: #3c3c3f;
    display: block;
    background-color: wheat;
    width: 260px;
    height: 120px;
    position: relative;
    padding-left: 40px;
    padding-right: 30px;
    border-radius: 10%;
    padding-top: 20px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.delete-comment {
    background-color: #abc9ff;
    width: 150px;
    position: relative;
    left: 85px;
    justify-content: center;
    display: flex;
    border-radius: 30%;
    padding: 10px;
}

.comment-padding {
    height: 20px;
}
