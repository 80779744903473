#landing {
    background-image: url("body_diagram.png");
    width: 580px;
    height: 600px;
    object-fit: cover;
    position: relative;
    left: 460px;
    border-radius: 25px;
    top: 30px;
}

.main-page-cover {
    height: 100%;
    width: 100%;
    background: #95a1aee3;
}

.greeting {
    background: #032d5ae3;
    position: absolute;
    top: 100px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
}

#arm-left {
    width: 20px;
    height: 100px;
    position: relative;
    top: 190px;
    left: 200px;
    transform: rotate(25deg);
}

#arm-left-link {
    width: 20px;
    height: 150px;
    position: relative;
    top: 190px;
    left: 200px;
    transform: rotate(25deg);
}

#arm-right {
    width: 20px;
    height: 100px;
    position: relative;
    top: 90px;
    right: -380px;
    transform: rotate(-25deg);
}

#arm-right-link {
    width: 20px;
    height: 150px;
    position: relative;
    top: 90px;
    right: -380px;
    transform: rotate(-25deg);
}

#chest {
    width: 80px;
    height: 50px;
    position: relative;
    left: 260px;
    top: -60px;
}

#chest-link {
    width: 80px;
    height: 50px;
    position: relative;
    left: 260px;
    top: -60px;
}

#abs {
    width: 80px;
    height: 100px;
    position: relative;
    left: 260px;
    top: -60px;
}

#abs-link {
    width: 80px;
    height: 100px;
    position: relative;
    left: 260px;
    top: -60px;
}

#leg-left {
    width: 20px;
    height: 150px;
    position: relative;
    top: 50px;
    left: 255px;
}

#leg-left-link {
    width: 20px;
    height: 150px;
    position: relative;
    top: 50px;
    left: 255px;
}

#leg-right {
    width: 20px;
    height: 150px;
    position: relative;
    top: -110px;
    left: 318px;
}

#leg-right-link {
    width: 20px;
    height: 150px;
    position: relative;
    top: -110px;
    left: 318px;
}



#arm-left-blurb {
    display: none;
    position: relative;
    min-width: 268px;
    min-height: 110px;
    border-radius: 25px;
    top: -40px;
    right: -700px;
}

#arm-right-blurb {
    display: none;
    position: relative;
    right: -580px;
    min-width: 268px;
    min-height: 110px;
    left: 700px;
    top: -150px;
    border-radius: 25px;
}

#chest-blurb {
    min-width: 268px;
    min-height: 110px;
    border-radius: 25px;
    display: none;
    position: relative;
    left: 680px;
    top: 60px;
}

#abs-blurb {
    min-width: 268px;
    min-height: 110px;
    border-radius: 25px;
    display: none;
    position: relative;
    right: -680px;
    top: -150px;
}

#leg-left-blurb {
    min-width: 268px;
    min-height: 110px;
    border-radius: 25px;
    display: none;
    position: relative;
    left: 680px;
    top: -200px;
}

#leg-right-blurb {
    min-width: 268px;
    min-height: 110px;
    border-radius: 25px;
    display: none;
    position: relative;
    right: -680px;
    top: -240px;
}

#arm-left:hover+#arm-left-blurb {
    display: block;
    color: white;
    background-color: #001a40;
    max-width: 80px;
    opacity: 1;
}

#arm-right:hover+#arm-right-blurb {
    display: block;
    color: white;
    background-color: #001a40;
    max-width: 80px;
    opacity: 1;
}

#chest:hover+#chest-blurb {
    display: block;
    color: white;
    background-color: #001a40;
    max-width: 80px;
    opacity: 1;
}

#abs:hover+#abs-blurb {
    display: block;
    color: white;
    background-color: #001a40;
    max-width: 80px;
    opacity: 1;
}

#leg-left:hover+#leg-left-blurb {
    display: block;
    color: white;
    background-color: #001a40;
    max-width: 80px;
    opacity: 1;
}

#leg-right:hover+#leg-right-blurb {
    display: block;
    color: white;
    background-color: #001a40;
    max-width: 80px;
    opacity: 1;
}



.empty-blurb {
    padding-left: 35px;
    inline-size: 200px;
    overflow-wrap: break-word;
}


#landing-intro {
    max-width: 268px;
    max-height: 310px;
    min-width: 268px;
    min-height: 310px;
    background-color: white;
    position: relative;
    top: -500px;
    border-radius: 25px;
    padding-top: 20px;
    left: 80px;
}